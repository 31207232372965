<template>
  <div class="loginfo-container">
    <!--工具条-->
    <el-col :span="24" class="toolbar" style="padding-bottom: 0px">
      <el-form :inline="true" :model="filters">
        <el-form-item label="门店">
          <shopSelector v-model="filters.tenant" />
        </el-form-item>
        <el-form-item label="训练营">
          <campSelector v-model="filters.camp" :tenant="filters.tenant" />
        </el-form-item>
        <el-form-item>
          <dateOptionSelector
            :options="dateOptions"
            v-model="filters.dateTime"
            :multiple="true"
          />
        </el-form-item>
        <el-form-item label="关键词">
          <el-input    size="small" v-model="filters.search" placeholder="关键字"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" v-on:click="queryPageList">查询</el-button>
        </el-form-item>
      </el-form>
    </el-col>
    <!--列表-->
    <el-table
      stripe
      border
      size="mini"
      :data="tableData"
      highlight-current-row
      expand-on-click-node="false"
      style="width: 100%"
    >
      <el-table-column
        prop="Camp.Name"
        label="训练计划"
        min-width="120"
        sortable
      ></el-table-column>
      <el-table-column
        prop="Member.Name"
        label="姓名"
        min-width="120"
        sortable
      >
      </el-table-column>
      <el-table-column
        prop="Member.Mobile"
        label="电话"
        min-width="120"
        sortable
      >
      </el-table-column>
      <el-table-column prop="Date" label="预约日期" min-width="120" sortable>
        <template slot-scope="scope">
          {{ format.dateFormat(scope.row.Date) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="AppointTime"
        label="预约时段"
        min-width="120"
        sortable
      ></el-table-column>
      <el-table-column prop="Status" label="状态" width="120" sortable>
        <template slot-scope="scope">
          {{ format.traningStatusFormat(scope.row.Status) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="Comment"
        label="总结"
        min-width="150"
        sortable
      ></el-table-column>
      <el-table-column fixed="right" :width="100" label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="text" @click="handleDelete(scope.row)"
            >删除</el-button
          >
          <el-button type="text" @click="handleUpdate(scope.row)"
            >总结</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination-container">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="pageSizesList"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :current-page.sync="pageIndex"
      ></el-pagination>
    </div>
    <campAppointForm ref="campAppointForm" @update="getPageList" />
  </div>
</template>
<script>
import { getPageList, del } from "@/api/camp/campAppoint";
import campAppointForm from "./components/campAppointForm";
import dateOptionSelector from "@/components/dateOptionSelector";
import campSelector from "./components/campSelector";
import shopSelector from "@/components/shopSelector";
export default {
  components: {
    campAppointForm,
    dateOptionSelector,
    campSelector,
    shopSelector
  },
  data() {
    return {
      dateOptions: [{ value: "date", name: "预约日期" }],
      filters: {
        search: "",
        camp: "",
        tenant: "",
        dateTime: {
          dateType: "date",
          date: [],
        },
      },
      tableData: [],
      total: 0,
      pageIndex: 1,
      pageSize: 10,
      pageSizesList: [10, 15, 20, 30, 50],
      dialogStatus: "",
    };
  },
  methods: {
    queryPageList: function () {
      let data = {
        pageSize: this.pageSize,
        pageIndex: this.pageIndex,
        search: this.filters.search,
        camp: this.filters.camp ? this.filters.camp.Key : "",
        tenant: this.filters.tenant ? this.filters.tenant.Key : "",
        beginDate:
          this.filters.dateTime.date.length > 0
            ? this.filters.dateTime.date[0]
            : "",
        endDate:
          this.filters.dateTime.date.length > 1
            ? this.filters.dateTime.date[1]
            : "",
      };
      getPageList(data).then((response) => {
        this.listLoading = false;
        if (response) {
          this.tableData = response.Items;
          this.total = response.Total || response.Items.length;
        }
      });
    },
    handleSizeChange(val) {
      var pageSize = `${val}`;
      this.pageIndex = 1;
      this.pageSize = parseInt(pageSize);
      this.$nextTick(() => this.queryPageList());
    },
    handleCurrentChange(val) {
      this.pageIndex = `${val}`;
      this.queryPageList();
    },
    handleAdd() {},
    handleDelete(row) {
      this.$confirm("确认删除该次预约吗？", "提示", {}).then(() => {
        let parma = { key: row.Key };
        del(parma).then((res) => {
          if (res) {
            this.msg.successMsg("删除成功!");
            this.queryPageList();
          }
        });
      });
    },
    handleUpdate(row) {
      this.$refs.campAppointForm.handleUpdate(row);
    },
  },
  mounted() {
    this.queryPageList();
  },
};
</script>
  
<style lang='scss' scoped>
.loginfo-container {
  padding: 10px;
}

/deep/.el-dialog {
  margin-top: 10px !important;
}
</style>
  